import React from "react";

function PrincipalMessage() {
  return (
    <div>
      <>
        {/* Start For Content */}
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-3">
              <img
                src="/assets/images/jatin.png"
                alt=""
                className="img-responsive img-thumbnail"
                width={261}
                height={200}
              />
              <br />
              <br />

              <h3>
                Prof. (Dr.) Jatin Niranjanbhai Dhagat
                <br />
              </h3>
              <p style={{}}>
                I/C Principal <br />
                Central Reg No- 171808 State no- 19665 <br />
                Qualification- M.D. [Hom.] <br />
                Contact : +91 9323691618 <br />
                Email : sshckalol@gmail.com
              </p>
            </div>
            <div className="col-md-9">
              <h2>
                Principal's Message
                <br />
              </h2>
              <p style={{ textAlign: "justify" }}>
                In India, after allopathy, Homoeopathy, is the most preferable
                mode of treatment, with about 100 millions (10 ) of people
                depending solely on Homoeopathy for their medical care. The
                demand for Homeopathy doctor ids huge and scope and potential
                unlimited. In Gujarat, Homoeopathy is growing very fast. Shree
                Swaminarayan Homeopathy College which was established in 2017-18
                committed to provide excellent and quality Homoeopathic Medical
                education to its students. A student after his education will
                come out as an health care provider to the community at large,
                who demonstrate empathy to his patients, is a lifelong learner.
                The focus is on all round growth who contribute to the nation
                and scientific and ethical in his profession. I assure all of
                you that the management with his vision and dedication and
                qualified staff will ensure the above. “Homoeopathy cures a
                large percentage of cases than any other method of treatment and
                is beyond all doubt safer, more economical and the most complete
                medical Science.
              </p>
            </div>
          </div>
          <br />
          <br />
        </div>{" "}
        {/* End For Content */}
      </>
    </div>
  );
}

export default PrincipalMessage;
