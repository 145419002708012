import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Router/RouterConfig";
import { profileData } from "./ProfileData";

function GoverningBody() {
  const navigate = useNavigate();

  const changeDir = (i) => {
    navigate(ROUTES.Profile, { state: { data: i } });
  };

  return (
    <div className="GoverningBody">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center">Governing Body</h1>
            <div className="card border-0 shadow-sm">
              <div className="card-body">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12 col-sm-8 col-lg-6">
                      {/* Section Heading*/}
                      <div
                        className="section_heading text-center wow fadeInUp"
                        data-wow-delay="0.2s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.2s",
                          animationName: "fadeInUp",
                        }}
                      >
                        {/* <h3>Engineering</h3> */}
                        <div className="line" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <h3 className="text-left">Chairman</h3>
                    <div className="col-12 col-sm-6 col-lg-3 align-center">
                      <div
                        onClick={() => {
                          changeDir(profileData[0]);
                        }}
                      >
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/images/Shastri+Swami+Premswarupdasji.jpeg"
                              alt="vicepresident"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Shastri Swami Premswarupdasji</h6>
                            <p className="designation"> President</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="card border-0 shadow-sm">
              <div className="card-body">
                <div className="container">
                  <div className="row">
                    <h3 className="text-left">Members</h3>
                    {/* Single Advisor*/}
                    <div className="col-12 col-sm-6 col-lg-3">
                      <div
                        onClick={() => {
                          changeDir(profileData[1]);
                        }}
                      >
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.3s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.3s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="../assets/images/academics/vicepresident3.jpg"
                              alt="vicepresident"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Swami Harikrishnaswarupdasji</h6>
                            <p className="designation">
                              Vice President
                              {/*Shree Swaminarayan Vishvamangal Gurukul</p>*/}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <div
                        onClick={() => {
                          changeDir(profileData[2]);
                        }}
                      >
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.3s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.3s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="../assets/images/academics/vicepresident-1.jpg"
                              alt="vicepresident"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>
                              {" "}
                              Swami
                              <p /> Bhaktavatsaldasji
                            </h6>
                            <p className="designation">
                              Vice President
                              {/*Shree Swaminarayan Vishvamangal Gurukul</p>*/}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <div
                        onClick={() => {
                          changeDir(profileData[3]);
                        }}
                      >
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="../assets/images/academics/vicepresident-2.jpg"
                              alt="vicepresident"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Swami Bhaktinandandasji</h6>
                            <p className="designation">
                              {" "}
                              Vice President
                              {/*Shree Swaminarayan Vishvamangal Gurukul</p>*/}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Single Advisor*/}
                    {/* Single Advisor*/}
                    <div className="col-12 col-sm-6 col-lg-3">
                      <div
                        onClick={() => {
                          changeDir(profileData[4]);
                        }}
                      >
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="../assets/images/academics/vc.jpg"
                              alt="vicepresident"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Prof (Dr.) M. S. Rao</h6>
                            <p className="designation">
                              {" "}
                              Provost (Vice-Chancellor) <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="../assets/images/academics/mnpatel.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Dr.M.N.Patel</h6>
                            <p className="designation">
                              {" "}
                              Eminent Educationist, Ahmedabad
                            </p>
                            <p></p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="../assets/images/academics/madhubhai.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Dr.Madhubhai M. Patel</h6>
                            <p className="designation"> Eminent Educationist</p>
                            <p>Ahmedabad</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="../assets/images/academics/janak.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Mr. Janak Khandwala</h6>
                            <p className="designation">Vice President</p>
                            <p>Silver Oaks University</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="../assets/images/academics/gunjan.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Dr. Gunjan Shah</h6>
                            <p className="designation">
                              Director (Administration)
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="../assets/images/academics/ravipatel.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Dr. Ravikumar R. Patel</h6>
                            <p className="designation">
                              {" "}
                              Dean, Faculty of Pharmacy
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="../assets/images/academics/rohit.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Dr. Rohit Gokarn</h6>
                            <p className="designation">
                              Dean, Faculty of Ayurveda
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="../assets/images/academics/geetanjali.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Dr. Geetanjali Amarawat</h6>
                            <p className="designation">
                              I/C Dean, Faculty of Engineering
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="../assets/images/academics/sureshbhai.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Mr. Sureshbhai N. Patel</h6>
                            <p className="designation">
                              {" "}
                              Director, Vinitech Metal Pvt.Ltd.
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <a href="#">
                        <div
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="../assets/images/academics/ShriMukeshSir.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6>Shri Mukesh Kumar</h6>
                            <p className="designation">
                              Principal Secretary, <br /> Govt. of Gujarat
                            </p>
                            <p>(Higher &amp; Tech.Edu)</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-12 col-sm-6 col-lg-3"
                  style={{ marginLeft: "26px" }}
                >
                  <a href="#">
                    <div
                      className="single_advisor_profile wow fadeInUp"
                      data-wow-delay="0.2s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.2s",
                        animationName: "fadeInUp",
                      }}
                    >
                      {/* Team Thumb*/}
                      <div className="advisor_thumb">
                        <img
                          src="../assets/images/academics/dhiraj.jpg"
                          alt="Members"
                          width="100%"
                        />
                        {/* Social Info*/}
                      </div>
                      {/* Team Details*/}
                      <div className="single_advisor_details_info">
                        <h6>CA Dhiraj Kaka</h6>
                        <p className="designation">Chartered Accountant</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  <a href="#">
                    <div
                      className="single_advisor_profile wow fadeInUp"
                      data-wow-delay="0.2s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.2s",
                        animationName: "fadeInUp",
                      }}
                    >
                      {/* Team Thumb*/}
                      <div className="advisor_thumb">
                        <img
                          src="../assets/images/academics/maheshsir.jpg"
                          alt="Members"
                          width="100%"
                        />
                        {/* Social Info*/}
                      </div>
                      {/* Team Details*/}
                      <div className="single_advisor_details_info">
                        <h6>Dr. Mahesh N. Patel </h6>
                        <p className="designation">Senior Advocate, Mehsana</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  <a href="#">
                    <div
                      className="single_advisor_profile wow fadeInUp"
                      data-wow-delay="0.2s"
                      style={{
                        visibility: "visible",
                        animationDelay: "0.2s",
                        animationName: "fadeInUp",
                      }}
                    >
                      {/* Team Thumb*/}
                      <div className="advisor_thumb">
                        <img
                          src="../assets/images/academics/rupeshbhai.jpg"
                          alt="Members"
                          width="100%"
                        />
                        {/* Social Info*/}
                      </div>
                      {/* Team Details*/}
                      <div className="single_advisor_details_info">
                        <h6> Mr. Rupeshbhai B. Patel</h6>
                        <p className="designation">Director, Industry</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              {/*</div>*/}
              <br />
              <div className="card border-0 shadow-sm">
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <h3 className="text-left">Member Secretary</h3>
                      <div className="col-12 col-sm-6 col-lg-3">
                        <div
                          onClick={() => {
                            changeDir(profileData[7]);
                          }}
                          className="single_advisor_profile wow fadeInUp"
                          data-wow-delay="0.2s"
                          style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInUp",
                          }}
                        >
                          {/* Team Thumb*/}
                          <div className="advisor_thumb">
                            <img
                              src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Governer+Body/ajit-sir-b.jpg"
                              alt="Members"
                              width="100%"
                            />
                            {/* Social Info*/}
                          </div>
                          {/* Team Details*/}
                          <div className="single_advisor_details_info">
                            <h6> Dr Ajit Gangavane </h6>
                            <p className="designation"> Registrar </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoverningBody;
