import React from "react";
import "./Profiles.scss";

function OtherUniversityOfficers() {
  return (
    <div className="OtherUniversityOfficers VicePresidents">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center"> University Officers</h1>
            <br />
            <br />
            <div className="card border-0 shadow-sm">
              <div className="card-body">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-12 col-sm-8 col-lg-6">
                          {/* Section Heading*/}
                          <div
                            className="section_heading text-center wow fadeInUp"
                            data-wow-delay="0.2s"
                            style={{
                              visibility: "visible",
                              animationDelay: "0.2s",
                              animationName: "fadeInUp",
                            }}
                          >
                            {/* <h3>Engineering</h3> */}
                            <div className="line" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Single Advisor*/}
                        <div className="col-12 col-sm-6 col-lg-3">
                          <a href="#">
                            <div
                              className="single_advisor_profile wow fadeInUp"
                              data-wow-delay="0.2s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0.2s",
                                animationName: "fadeInUp",
                              }}
                            >
                              <div className="advisor_thumb">
                                <img
                                  src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Hitendrakumar.jpg"
                                  alt="Officers"
                                  width="100%"
                                />
                              </div>

                              <div
                                className="single_advisor_details_info"
                                style={{ height: "185px" }}
                              >
                                <h6>Hitendrakumar Natvarlal Barot</h6>
                                <p className="designation">
                                  Hon'ble Public Relations Officer
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3">
                          <a href="#">
                            <div
                              className="single_advisor_profile wow fadeInUp"
                              data-wow-delay="0.2s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0.2s",
                                animationName: "fadeInUp",
                              }}
                            >
                              <div className="advisor_thumb">
                                <img
                                  src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Governer+Body/Swami+Nityaswardupdasji.jpg"
                                  alt="Officers"
                                  width="100%"
                                />
                              </div>

                              <div className="single_advisor_details_info">
                                <h6>
                                  Swami Nityaswarupdasji Bhaktavatsaldasji
                                </h6>
                                <p className="designation">Manager</p>
                                <p>(Campus Culture & Values)</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                          <a href="#">
                            <div
                              className="single_advisor_profile wow fadeInUp"
                              data-wow-delay="0.2s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0.2s",
                                animationName: "fadeInUp",
                              }}
                            >
                              <div className="advisor_thumb">
                                <img
                                  src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Governer+Body/Swami+Karunasagardasji.jpg"
                                  alt="Officers"
                                  width="100%"
                                />
                              </div>

                              <div className="single_advisor_details_info">
                                <h6> Swami Karunasagardasji</h6>
                                <p className="designation">Manager</p>
                                <p>(Catering, Stores & Constructions) </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                          <a href="#">
                            <div
                              className="single_advisor_profile wow fadeInUp"
                              data-wow-delay="0.2s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0.2s",
                                animationName: "fadeInUp",
                              }}
                            >
                              <div className="advisor_thumb">
                                <img
                                  src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Governer+Body/Swami+Ghanshyamdasji.jpg"
                                  alt="Officers"
                                  width="100%"
                                />
                              </div>

                              <div className="single_advisor_details_info">
                                <h6>Swami Ghanshyamdasji</h6>
                                <p className="designation">Manager</p>
                                <p>(Estate, Electrical & Maintenance)</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        {/* Single Advisor*/}
                      </div>
                      <div className="row">
                        {/* Single Advisor*/}
                        {/* Single Advisor*/}
                        <div className="col-12 col-sm-6 col-lg-3">
                          <a href="#">
                            <div
                              className="single_advisor_profile wow fadeInUp"
                              data-wow-delay="0.2s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0.2s",
                                animationName: "fadeInUp",
                              }}
                            >
                              <div className="advisor_thumb">
                                <img
                                  src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Governer+Body/Swami+Shyamsundardasji.jpg"
                                  alt="Officers"
                                  width="100%"
                                />
                              </div>

                              <div className="single_advisor_details_info">
                                <h6>
                                  Swami Shyamsundardasji Bhaktinandandasji
                                </h6>
                                <p className="designation">
                                  Manager (Social Media & Communication){" "}
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                          <a href="#">
                            <div
                              className="single_advisor_profile wow fadeInUp"
                              data-wow-delay="0.2s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0.2s",
                                animationName: "fadeInUp",
                              }}
                            >
                              {/* Team Thumb*/}
                              <div className="advisor_thumb">
                                <img
                                  src="assets/images/academics/anita.jpg"
                                  alt="Officers"
                                  width="100%"
                                  height="380px"
                                />
                                {/* Social Info*/}
                              </div>
                              {/* Team Details*/}
                              <div className="single_advisor_details_info">
                                <h6> Dr. Anita Patel</h6>
                                <p className="designation">
                                  {/* I/C Dean, Research */}
                                  I/C Controller of Examination
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        {/* Single Advisor*/}

                        <div className="col-12 col-sm-6 col-lg-3">
                          <a href="#">
                            <div
                              className="single_advisor_profile wow fadeInUp"
                              data-wow-delay="0.3s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0.3s",
                                animationName: "fadeInUp",
                              }}
                            >
                              {/* Team Thumb*/}
                              <div className="advisor_thumb">
                                <img
                                  src="assets/images/academics/ravipatel.jpg"
                                  alt="Officers"
                                  width="100%"
                                  height="380px"
                                />
                                {/* Social Info*/}
                              </div>
                              {/* Team Details*/}
                              <div className="single_advisor_details_info">
                                <h6>Dr. Ravikumar Patel</h6>
                                <p className="designation">
                                  {/* I/C Deputy Registrar (Academics) */}
                                  I/C Director,(Research)
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                          <a href="#">
                            <div
                              className="single_advisor_profile wow fadeInUp"
                              data-wow-delay="0.3s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0.3s",
                                animationName: "fadeInUp",
                              }}
                            >
                              {/* Team Thumb*/}
                              <div className="advisor_thumb">
                                <img
                                  src="assets/images/academics/amol.jpg"
                                  alt="Officers"
                                  width="100%"
                                  height="340px"
                                />
                                {/* Social Info*/}
                              </div>
                              {/* Team Details*/}
                              <div className="single_advisor_details_info">
                                <h6>Dr. Amol Koshti</h6>
                                <p className="designation">
                                  {" "}
                                  I/C Deputy Registrar
                                </p>
                                <p>(Accreditation &amp; Compliance)</p>
                              </div>
                            </div>
                          </a>
                        </div>
                        {/* Single Advisor*/}

                        <div className="col-12 col-sm-6 col-lg-3">
                          <a href="#">
                            <div
                              className="single_advisor_profile wow fadeInUp"
                              data-wow-delay="0.3s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0.3s",
                                animationName: "fadeInUp",
                              }}
                            >
                              {/* Team Thumb*/}
                              <div className="advisor_thumb">
                                <img
                                  src="assets/images/academics/gunjan.jpg"
                                  alt="Officers"
                                  width="100%"
                                  height="360px"
                                />
                                {/* Social Info*/}
                              </div>
                              {/* Team Details*/}
                              <div className="single_advisor_details_info">
                                <h6>Dr. Gunjan Shah</h6>
                                <p className="designation">
                                  {/* Controller of Examination */}
                                  I/C Director (Admissions & Administration)
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3">
                          <a href="#">
                            <div
                              className="single_advisor_profile wow fadeInUp"
                              data-wow-delay="0.3s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0.3s",
                                animationName: "fadeInUp",
                              }}
                            >
                              {/* Team Thumb*/}
                              <div className="advisor_thumb">
                                <img
                                  src="assets/images/academics/vijay.jpg"
                                  alt="Officers"
                                  width="100%"
                                  height="340px"
                                />
                                {/* Social Info*/}
                              </div>
                              {/* Team Details*/}
                              <div className="single_advisor_details_info">
                                <h6>Dr.Vijay Gadhavi</h6>
                                <p className="designation">
                                  I/C Deputy Registrar(Innovations & Projects)
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        {/* Single Advisor*/}

                        <div className="col-12 col-sm-6 col-lg-3">
                          <a href="#">
                            <div
                              className="single_advisor_profile wow fadeInUp"
                              data-wow-delay="0.3s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0.3s",
                                animationName: "fadeInUp",
                              }}
                            >
                              {/* Team Thumb*/}
                              <div className="advisor_thumb">
                                <img
                                  src="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Dr.+Chandramauli+Raju.jpg"
                                  alt="Officers"
                                  width="100%"
                                  height="340px"
                                />
                                {/* Social Info*/}
                              </div>
                              {/* Team Details*/}
                              <div className="single_advisor_details_info">
                                <h6>Dr. Chandramauli Raju</h6>
                                <p className="designation">
                                  I/C Director (International Affairs)
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3">
                          <a href="#">
                            <div
                              className="single_advisor_profile wow fadeInUp"
                              data-wow-delay="0.3s"
                              style={{
                                visibility: "visible",
                                animationDelay: "0.3s",
                                animationName: "fadeInUp",
                              }}
                            >
                              {/* Team Thumb*/}
                              <div className="advisor_thumb">
                                <img
                                  src="/assets/images/devesh.png"
                                  alt="Officers"
                                  width="100%"
                                  height="340px"
                                />
                                {/* Social Info*/}
                              </div>
                              {/* Team Details*/}
                              <div className="single_advisor_details_info">
                                <h6>Er. Devesh Varma</h6>
                                <p className="designation">IT MANAGER</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtherUniversityOfficers;
